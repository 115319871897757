import React from "react";
// import Section from "./section";
// import Thumbnail1 from "./assets/img/thumb8.png"
// import Thumbnail2 from "./assets/img/thumb9.png"

export default function Footer() {
  return (
    <>
      <section className="footer" id="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="footer-address">
                <br />
                <p>
                  <i className="fa fa-file-text-o" />
                  사업자등록번호: 762-38-01264
                  <i className="fa fa-globe" />
                  통신판매업번호: 2024-인천연수구-4151
                  <i className="fa fa-user" />
                  대표자: 이선경
                </p>
                <p>
                  <i className="fa fa-map-marker" />
                  인천광역시 연수구 인천타워대로 197번길 16 1층 191호(우 :
                  22009)
                  <i className="fa fa-phone" />
                  (0502) 1900-0528
                </p>
              </div>
              <div className="footer-social-icons">
                <p>
                  <a
                    href="https://instagram.com/mil_bridge"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram" />
                  </a>
                  <a
                    href="https://smartstore.naver.com/milbridge/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-shopping-cart" />
                  </a>
                  <a
                    href="mailto:support@milbridge.cafe"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-envelope-o" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        {FooterCopyRights()}
      </section>
    </>
  );

  function FooterCopyRights() {
    return (
      <div className="footer-copyrights">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>
                <i className="fa fa-copyright" /> 2024. Milbridge. All rights
                reserved. Product with{" "}
                <i className="fa fa-heart primary-color" /> dibspoon, inc.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
