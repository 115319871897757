import React from "react";

export default function Header() {

	return (
		<>
			<div>
				<div>
					<meta charSet="utf-8" />
					<title>밀브릿지 베이커리에 방문해주셔서 감사합니다.</title>
					<meta name="author" content="bakery milbridge" />
					{/* SEO */}
					<meta name="description" content="밀브릿지 베이커리는 천연발효종을 사용하여 건강하고 속이편한 빵을 만들고 있습니다." />
					<meta name="keywords" content="베이커리, 밀브릿지,치아바타, 사워도우, 브레첼, 베이첼, ciabatta, sourdough, brezel" />
					{/* Favicons */}
					<link rel="shortcut icon" href="./assets/img/favicon.ico" />
					{/* Responsive Tag */}
					<meta name="viewport" content="width=device-width" />
					{/* CSS Files */}
					{/* <link rel="stylesheet" href="assets/css/main.css" /> */}
					<link rel="stylesheet" href="./assets/css/main.css" />
					{/* <link rel="stylesheet" href="assets/css/plugin.css" /> */}
					<link rel="stylesheet" href="./assets/css/plugin.css" />
					{/* <link rel="stylesheet" href="assets//css/bootstrap.min.css" /> */}
					<link rel="stylesheet" href="./assets/css/bootstrap.min.css" />
					<link rel="stylesheet" href="./assets/js/vendor/vegas/vegas.min.css" />
					{/* <link rel="stylesheet" href="assets//css/font-awesome/css/font-awesome.css" /> */}
					<link rel="stylesheet" href="./assets/css/font-awesome/css/font-awesome.css" />
					{/*
					*/}
					<script src="js/vendor/jquery-1.11.2.min.js"></script>
					<script src="js/vendor/bootstrap.min.js"></script>
					<script src="js/vendor/jquery.flexslider-min.js"></script>
					<script src="js/vendor/spectragram.js"></script>
					<script src="js/vendor/owl.carousel.min.js"></script>
					<script src="js/vendor/velocity.min.js"></script>
					<script src="js/vendor/velocity.ui.min.js"></script>
					<script src="js/vendor/bootstrap-datepicker.min.js"></script>
					<script src="js/vendor/bootstrap-clockpicker.min.js"></script>
					<script src="js/vendor/jquery.magnific-popup.min.js"></script>
					<script src="js/vendor/isotope.pkgd.min.js"></script>
					<script src="js/vendor/slick.min.js"></script>
					<script src="js/vendor/wow.min.js"></script>
					<script src="js/animation.js"></script>
					<script src="js/vendor/vegas/vegas.min.js"></script>
					<script src="js/vendor/jquery.mb.YTPlayer.js"></script>
					<script src="js/vendor/jquery.stellar.js"></script>
					<script src="js/main.js"></script>
					<script src="js/vendor/mc/jquery.ketchup.all.min.js"></script>
					<script src="js/vendor/mc/main.js"></script>
				</div>
			</div>
		</>
	);
}
