import React from "react";

// import thumb1 from "./assets/img/thumb1.png";
// import thumb2 from "./assets/img/thumb2.png";
// import thumb3 from "./assets/img/thumb3.png";
// import pic1 from "./assets/img/fullImages/pic1.jpg";
// import pic2 from "./assets/img/fullImages/pic2.jpg";
// import pic3 from "./assets/img/fullImages/pic3.jpg";

import thumb1 from "./assets/img/ourstory1.png";
import thumb2 from "./assets/img/ourstory2.png";
import thumb3 from "./assets/img/ourstory3.png";


import signature from "./assets/img/signature.png";


export default function BrandStory() {
	return (
		<>
			<section className="ourstory" id="ourstory">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="page-header wow fadeInDown">
								<h2>Our Story
								</h2>
								<br/>
								<small>'밀브릿지(Milbridge)'는 좋은 재료를 사용하여 건강한 빵을 만들어 제공하는 '가교' 역할을 한다는 의미를 담고 있습니다. </small>
							</div>
						</div>
						</div>
						<div className="row wow fadeInUp">
						<div className="col-md-4">
							<div className="container-fluid">
								<div className="row">
									<div className="col-xs-12 hidden-sm about-photo">
									<div className="image-thumb">
										{/* <img src={thumb1} data-mfp-src={pic1} className="img-responsive" alt="logo" /> */}
										<img src={thumb1} className="img-responsive" alt="logo" />
									</div>
									</div>
								</div>
								<br/>
								<div className="row">
									<div className="col-sm-6 about-photo hidden-xs">
									{/* <img src={thumb2} data-mfp-src={pic2} className="img-responsive" alt="logo" /> */}
									<img src={thumb2} className="img-responsive" alt="logo" />
									</div>
									<div className=
									"col-sm-6 about-photo hidden-xs">
									{/* <img src={thumb3} data-mfp-src={pic3} className="img-responsive" alt="logo" /> */}
									<img src={thumb3} className="img-responsive" alt="logo" />
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-8 text-left">
							<p>
								{/* <h4 className="text-left">'밀브릿지(Milbridge)'는 좋은 재료를 사용하여 건강한 빵을 만들어 제공하는 '가교' 역할을 한다는 의미를 담고 있습니다.</h4> */}
								{/* <br/> */}
								제품 개발단계에서 부터 좋은 품질의 재료를 엄선하여 정성스럽게 빵을 만들어 내며,
								전통적인 베이킹 기술을 고수하면서도 혁신적인 접근을 통해 더 맛있고 건강한 제품을 만들어 내려고 노력하고 있습니다.<br/>
								<br/>
								우리는 고객들에게 단순히 빵을 제공하는 것 이상의 가치를 전달하고자 합니다. <br/>
								우리의 제품은 재료의 품질과 전통적인 제빵 기술이 결합된 결과물입니다. <br/>
								건강한 라이프스타일을 추구하는 고객들에게 보다 좋은 옵션을 제공하여 삶에 긍정적인 변화를 가져다주고자 합니다.<br/>
								<br/>
								우리는 빵을 먹을 때마다 건강과 만족을 느낄 수 있도록 최선을 다하고 있습니다. <br/>
								밀브릿지 베이커리는 당신의 건강한 식단을 지원하고, 당신의 삶에 더 많은 행복과 만족감을 채워 줄 것입니다.
							</p>
						</div>
						<div className="col-md-10 text-right">
							<img className="signature" src={signature} alt="signature" />
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
