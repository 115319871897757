import React from "react";

import menu1_1 from "./assets/img/products/11.png";
import menu1_2 from "./assets/img/products/22.png";
import menu1_3 from "./assets/img/products/33.png";
// import menu1_4 from "./assets/img/products/4.jpg";
// import menu1_5 from "./assets/img/products/5.jpg";
// import menu1_6 from "./assets/img/products/6.jpg";
// import menu1_7 from "./assets/img/products/7.jpg";

export default function Menu() {
	return (
		<>
            <section className="menu menu2" id="menu">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="page-header wow fadeInDown">
								{/* <h2>Our Bread<small>'밀브릿지(Milbridge)'는 유럽풍의 다양한 빵을 선보이고 있습니다.</small></h2> */}
								<h2>Our Bread</h2><br></br>
								<small>'밀브릿지(Milbridge)'를 대표하는 빵들을 소개합니다.</small>
							</div>
						</div>
						</div>
						<div className="food-menu wow fadeInUp">
						{/* <div className="row">
							<div className="col-md-12">
							<div className="menu-tags2">
								<span data-filter="*" className="tagsort2-active">밀브릿지를 대표하는 빵을 소개합니다.</span>
								<span data-filter=".sourdough">사워도우</span>
								<span data-filter=".ciabatta">치아바타</span>
								<span data-filter=".brezel">브레첼</span>
							</div>
							</div>
						</div> */}
						<div className="row menu-items2">
							<div className="menu-item2 col-sm-4 col-xs-12 starter dinner desserts">
								<div className="menu-info">
									<img src={menu1_1} className="img-responsive" alt="" />
									<a href="https://smartstore.naver.com/milbridge/products/7139813338" target="_blank" rel="noreferrer">
									{/* <a href="./menu_all.html"> */}
										<div className="menu2-overlay">
											<h4>사워도우(Sourdough)</h4>
											<br />
											<p>적절한 산미를 느낄 수 있는
												<br />입문용 사워도우 입니다.</p>
											{/* <span className="price">스토어 바로가기</span> */}
											<span className="price">
												<a className="btn btn-default" href="https://smartstore.naver.com/milbridge/products/7139813338" role="button" target="_blank" rel="noreferrer">스토어 바로가기</a>
											</span>
										</div>
									</a>
								</div>
								{/* <a href="./menu_all.html" className="menu-more">+</a> */}
							</div>
							<div className="menu-item2 col-sm-4 col-xs-12 starter">
								<div className="menu-info">
									<img src={menu1_2} className="img-responsive" alt="" />
									{/* <a href="./menu_all.html"> */}
									<a href="https://smartstore.naver.com/milbridge/products/7197572343" target="_blank" rel="noreferrer">
									<div className="menu2-overlay">
										<h4>차아바타(ciabatta)</h4>
										<br />
										<p>빵의 향과 맛의 풍미가 특징 
										<br />입맛을 끌어올리는 고소함</p>
										<span className="price">
											<a className="btn btn-default" href="https://smartstore.naver.com/milbridge/products/7197572343" role="button" target="_blank" rel="noreferrer">스토어 바로가기</a>
										</span>
									</div>
									</a>
								</div>
								{/* <a href="./menu_all.html" className="menu-more">+</a> */}
							</div>
							<div className="menu-item2 col-sm-4 col-xs-12 breakfast desserts starter">
								<div className="menu-info">
									<img src={menu1_3} className="img-responsive" alt="" />
									<a href="https://smartstore.naver.com/milbridge/products/9665711758" target="_blank" rel="noreferrer">
									{/* <a href="./menu_all.html"><a href="https://smartstore.naver.com/milbridge/products/7197572343" target="_blank" rel="noreferrer"> */}
									<div className="menu2-overlay">
										<h4>브레첼(Brezel)</h4>
										<br />
										<p>겉은 쫄깃하고,속은 부드러운.
										<br />소화가 잘 되는 건강함</p>
										<span className="price">
											<a className="btn btn-default" href="https://smartstore.naver.com/milbridge/products/9665711758" role="button" target="_blank" rel="noreferrer">스토어 바로가기</a>
										</span>
									</div>
									</a>
								</div>
								{/* <a href="./menu_all.html" className="menu-more">+</a> */}
							</div>
						</div>
					</div>
				</div>
			</section>

		</>
	);
}
