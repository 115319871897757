import React from "react";
import PreLoader from "./assets/img/preloader.svg"


export default function Loading() {
	return (
		<div className="preloder animated">
			<div className="scoket">
				<img src={PreLoader} alt="" />
			</div>
		</div>
	);
}
