import React from "react";
// import PreLoader from "./assets/img/preloader.svg";

// interface BodyProps {
// 	children: React.ReactNode;
// }

export default function Main({ children }) {
	return (
		<>
			<body data-scroll-animation="true" id="intro3">
				{/* <div class="preloder animated">
					<div class="scoket">
						<img src={PreLoader} alt="" />
					</div>
				</div> */}

				<div class="body">
					<div class="main-wrapper">
						{children}
					</div>
				</div>
			</body>
		</>
	);
}


