// import logo from './logo.svg';
import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './header';
import Home from './home';
import Main from './main';
import Navbar from './navbar';
import Footer from "./footer";
import BrandStory from './brandstory';
import Menu from './menu';
// import OurStory from './ourstory';
import Loading from './loading'; // Add this line to import the 'Loading' component
// import Service from './service';
import Seo from './seo';

// import BestMenu from './bestmenu'; // Add this line to import the 'BestMenu' component

function App() {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);

  // <title>밀브릿지 베이커리에 방문해주셔서 감사합니다.</title>
	// 				<meta name="author" content="bakery milbridge" />
	// 				{/* SEO */}
	// 				<meta name="description" content="밀브릿지 베이커리는 천연발효종을 사용하여 건강하고 속이편한 빵을 만들고 있습니다." />
	// 				<meta name="keywords" content="베이커리, 밀브릿지,치아바타, 사워도우, 브레첼, 베이첼, ciabatta, sourdough, brezel" />

    // <BrowserRouter>
    // <App />
    // </BrowserRouter>
    // </HelmetProvider>

  return (
    <div className="App">
     
      <Seo
        title="밀브릿지 베이커리에 방문해주셔서 감사합니다."
        description="밀브릿지 베이커리는 천연발효종을 사용하여 건강하고 속이편한 빵을 만들고 있습니다."
        name="Milbridge Bakery"
        keywords="베이커리 밀브릿지 치아바타 사워도우 브레첼 베이첼 ciabatta sourdough brezel"
        type="Landing Site" />
      <Header />
        <Main>
          {/* <Loading /> */}
          {!loaded && <Loading />}
          <Navbar />
          <Home />
          {/* <OurStory /> */}
          <BrandStory />
          {/* <Service /> */}
          <Menu />
          {/* <BestMenu /> */}
        </Main>
      <Footer />
    </div>
  );
}

export default App;
