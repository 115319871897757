/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import Logo from "./assets/img/logo.png"
import ArrowDown from "./assets/img/arrow-down.png"

import Velocity from 'velocity-animate';

// <!-- Home page-->
export default function Home() {

	console.log('Call Home')
	const animationRef = useRef();
	const titleRef = useRef();
	const descriptionRef = useRef();

	async function initAnimation() {
		await Velocity(animationRef.current, {
			opacity: 0
		}, {
			duration: 0
		});

		await Velocity(titleRef.current, {
			opacity: 0
		}, {
			duration: 0
		});

		await Velocity(descriptionRef.current, {
			opacity: 0
		}, {
			duration: 0
		});
	}

	async function animationLogo() {
		await Velocity(animationRef.current, {
			opacity: 1
		}, {
			duration: 3000
		}).then(function(element) { // Make the function async
			console.log("[animationLogo] complete");
			// animationTitle();
		});
	}

	async function animationTitle() {
		await Velocity(titleRef.current, {
			opacity: 1
		}, {
			duration: 1000
		}).then(function(element) {
			console.log("[animationTitle] complete");
			// animationDescription();
		});
	}

	async function animationDescription() {

		await Velocity(descriptionRef.current, {
			opacity: 1
		}, {
			duration: 1000
		}).then(function(element) {
			console.log("[animationDescription] complete");
		});
	}

	async function executeAnimation() {
		await initAnimation();
		await animationLogo();
		await animationTitle();
		await animationDescription();
	}
	//처음 렌더링이 될 때만 실행
	useEffect(() => {
		executeAnimation();
	}, []);

	return (
		<>
			<section className="home" id="home">
				<div class="overlay"></div>
				<div className="tittle-block">
					<div className="logo" ref={animationRef} >
						<a href="./">
							<img src={Logo} alt="logo" />
						</a>
					</div>
					<h1 ref={titleRef}>모두가 함께 즐기는 건강한 맛</h1>
					<h2 ref={descriptionRef}>밀브릿지 빵으로 맛과 건강을 동시에 경험해 보세요.</h2>
				</div>
				<div className="scroll-down">
					<a href="#ourstory">
						<img src={ArrowDown} alt="down-arrow" />
					</a>
				</div>
			</section>
		</>
	);
}


