import React, { useState } from "react";
// import navLogo from "./assets/img/nav-logo.png"
import ciText from "./assets/img/ci_text.png"
// import 'bootstrap/dist/css/bootstrap.min.css';


// export function Navbar({ children }: NavbarProps) {
 export default function Navbar() {

	// navbar-toggle id="navbar"  className="navbar-collapse collapse" className="navbar-collapse collapse in" make toggle function
	// onclick="displayMenu()"

	const [isOpen, setIsOpen] = useState(false);

	function toggleMenu() {
		console.log('toggleMenu');
		setIsOpen(!isOpen);
	}

	return (
		<>
			{/* <link rel="stylesheet" href="./css/bootstrap.min.css" /> */}

			<nav className="navbar navbar-fixed-top">
				<div className="container">
					<div className="navbar-header">
					<button
						type="button"
						className="navbar-toggle collapsed"
						data-toggle="collapse"
						data-target="#navbar"
						aria-expanded="false"
						aria-controls="navbar"
						onClick={toggleMenu}
					>
						<span className="sr-only">Toggle navigation</span>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
					</button>
					<a className="navbar-brand" href="./index.html">
						<img src={ciText} alt="nav-logo" />
					</a>
					</div>
					{/* <div id="navbar" className="navbar-collapse collapse"> */}
					{/* <div id="navbar" className={`navbar-collapse ${isOpen ? "collapse in" : "collapse"}`} aria-expanded={`${!!isOpen}`} style={`${!isOpen ? "height: 1px" : ""}`}> */}
					<div id="navbar" className={`navbar-collapse ${isOpen ? "collapse in" : "collapse"}`} aria-expanded={`${!!isOpen}`} 
					style={!isOpen ? {height: '1px'} : {}}>
						<ul className="nav navbar-nav navbar-right">
							{/* <li>
								<a href="#home" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Home<span class="caret"></span></a>
							</li> */}
							<li>
								<a href="#home">Home</a>
							</li>
							<li>
								<a href="#ourstory">Our Story</a>
							</li>
							<li>
								<a href="#menu">Our Breads</a>
							</li>
							<li>
								<a href="#footer">Contact Us</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
}

